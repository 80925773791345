$(document).on('turbolinks:load', function() {
    $('#ceo_video, .play_video, button#replyAllButton, button.single_reply_button').on('click', function() {
        $(window).scrollTop(0);
    });

    $('#ceoVideoModal').on('hidden.bs.modal', function () {
        document.getElementById("ceoVideo").pause();
    }).on('shown.bs.modal', function () {
        document.getElementById("ceoVideo").play();
    });

    $('.modal').on('shown.bs.modal', function () {
        $(".modal-title", this).focus();
    }).on('hidden.bs.modal', function () {
        temp = document.getElementById('colleague_video');
        if (temp) {
            temp.pause();
        }
        if (preModalFocus) {
            $("#" + preModalFocus).focus();
        }
    }).on('shown.bs.modal', function () {
        $(".modal-title", this).focus();
    }).on('hidden.bs.modal', function () {
        $(".modal-backdrop").remove();
    });

    var preModalFocus = '';
    $('.single_reply_button, .reply-container').on('click', function () {
        if ($(this).attr('id')) {
            preModalFocus = $(this).attr('id');
        }
    });

    // open modal
    $('.actions').click(function () {
        $('body').toggleClass("modal-open");
        var uiop = $(this).find(".uiop");
        var url = $(uiop).attr('href1');
        // load the url and show modal on success
        var id = $(uiop).attr('data-target');
        //prevent modal from doubleclick
        $(id).modal({backdrop: 'static', keyboard: true});
        $(id).modal("show");
        $.get(url, function (data) {
            $(id + ' .modal-body').html(data);
        })
    });

    if (!!document.getElementById('scrollToCards')) {
        document.getElementById('scrollToCards').addEventListener('click', function () {
            document.getElementById('ecards_heading').scrollIntoView({
                behavior: 'smooth'
            });
        });
    }

    $("div.footer-anchors > a").attr("href", "");

    if ($("a.caret")) {
        $(".caret").remove();
    }
});
