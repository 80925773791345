// returns the values associated with and ecard_id. If the ecard_id
// isn't found then return undefined.
function search_ecards(ecard_id) {
    for (var i in window.ecards.cards) {
        if (window.ecards.cards[i].values.ecard_id == ecard_id) {
            return window.ecards.cards[i].values;
        }
    }
}

// DOM Event Listeners
$(function() {
    // PDF
    $('.pdf-container').on('click', function(){
        Sender.pdf($(this).attr('link'));
    });

    // PrintAll
    $('#printAll').on('click', function(){
        $('#letter-print').html('');
        Sender.printEcards();
    })

    //print Single Card
    $('.print_ecard').on('click', function(){
        $('#letter-print').html('');
        Sender.printSingleCard(this.id);
    })

    //print Letter
    $('#printLetter').on('click', function(){
        $('#letter-print').html('');
        Sender.printLetter();
    })

    //show single reply modal
    $('.single_reply_button').on('click', function(){
        var key = $(this).attr('id');
        var temp = key.replace(/\D/g, '');
        $('#reply-single-error-message').empty();
        $('textarea.single-reply-message-body').val('');
        $('ul.singleRecip li').remove();
        $('#singleSentStatus').text('');
        $('.send-message-single').prop('disabled', false)
                                 .removeClass('disabled')
                                 .attr('id', key);
        var obj = search_ecards(temp);
        $('ul.singleRecip').append('<li class=\"replyEmails\" data-sender_name=\"' + obj.sender_name + '\">' + obj.sender_email + '</li>');
    });

    // show reply-modal
    $('#replyAll').on('click', function(){
        $('#reply-multi-error-message').empty();
        $('textarea.multi-reply-message-body').val('');
        $('ul.recips li').remove();
        $('#multiSentStatus').text('');
        $('#send-message').prop('disabled', false)
                          .removeClass('disabled');
        var obj =  window.ecards.cards;
        var temp = $('ul.recips');
        for(var i in obj){
            element = obj[i].values
            temp.append('<li class=\"replyEmails\" data-sender_name=\"' + element.sender_name + '\">' + element.sender_email + '</li>');
        }
    });

    // Reply to single sender - send button on the modal
    $('.send-message-single').on('click', function(){
        if ($.trim($(".single-reply-message-body").val())) {
            Sender.replyToSingleSender(this.id);
            $('#reply-single-error-message').html('');
            $('.send-message-single').addClass('disabled')
                                     .prop('disabled', true);
            $('.send-message-single .fa-spinner').removeClass("d-none");
        }else{
            var errorMsg = "<div class=\"alert alert-danger float-left col-12\" role=\"alert\">Please enter a message before sending.</div>";
            $('#reply-single-error-message').html(errorMsg);
            $('#singleSentStatus').html('');
        }
    });

    // Reply to senders - send button on the modal
    $('#send-message').on('click', function(){
        if ($.trim($(".multi-reply-message-body").val())) {
            Sender.replyToSender();
            $('#reply-multi-error-message').html('');
            $('#send-message').addClass('disabled')
                              .prop('disabled', true);
            $('#send-message .fa-spinner').removeClass("d-none");
        }else{
            var errorMsg = "<div class=\"alert alert-danger float-left col-12\" role=\"alert\">Please enter a message before sending.</div>";
            $('#reply-multi-error-message').html(errorMsg);
            $('#multiSentStatus').html('');
        }
    });

    // Reset reply to sender modal on dismiss
    $('#single-reply-modal').on('hidden', function(){
        $('.single-reply-modal h4').text('Sending message to');
        $('textarea.single-reply-message-body').val('');
        $('ul.recips li, .error').remove();
    });


    // Reset reply to sender modal on dismiss
    $('#multi-reply-modal').on('hidden', function(){
        $('.multi-reply-modal h4').text('Sending message to');
        $('textarea.multi-reply-message-body').val('');
        $('ul.recips li, .error').remove();
    });

}); // end DOM Event Listeners

Sender = function() {
    // private properties
    var queue = [];
    var privateCurrentView = [];

    return {
        // Getters Setters
        getCheckedQueue: function(){
            var myQueue = [];
            for(var i=0; i<queue.length; i++)
                myQueue.push(queue[i])
            return myQueue;
        },

        addToQueue: function(id){
            var myEcards = Renderer.getEcards();
            for(var i=0; i<myEcards.length; i++)
                if(id == myEcards[i].id){
                    $('[data-ecard="' + id + '"] input').prop('checked', true);
                    queue.push(myEcards[i])
                }
        },

        removeFromQueue: function(id){
            var myQueue = Sender.getCheckedQueue();
            for(var i=0; i<myQueue.length; i++)
                if(id == myQueue[i].id){
                    $('[data-ecard="' + id + '"] input').prop('checked', false);
                    queue.splice(i, 1)
                }
        },

        resetQueue: function(){
            queue = [];
        },

        setCurrentView: function(ecards){
            privateCurrentView = [];
            for(var i=0; i<ecards.length; i++)
                privateCurrentView.push(ecards[i])
        },

        getCurrentView: function(){
            var myprivateCurrentView = [];
            for(var i=0; i<privateCurrentView.length; i++)
                myprivateCurrentView.push(privateCurrentView[i])
            return myprivateCurrentView;
        },

        replyToSingleSender: function(id){
            var toQueue = [];
            var temp = $('ul.singleRecip > li');
            toQueue.push({
                sender_name: temp.attr('data-sender_name'),
                email: temp.text()
            });

            var data = JSON.stringify({
                from: {
                    eligibilityID: $('#eligibility_id').val(),
                    name: $('input[name="recip-name"]').val(),
                    message: htmlEscape( $('textarea.single-reply-message-body').val() )
                },
                to: toQueue
            });

            var url  = CONFIG.rw_domain + '/app/core/proc/Dashboard/Widget/EcardSend/ReplyToSenders';
            var xhr = createCORSRequest('POST', url);

            if (!xhr){
                $('.send-message-single').prop('disabled', false)
                                         .removeClass('disabled');
                alert('Sorry, your Web Browser does not support this functionality.');
                return;
            }

            // FAILURE
            xhr.onerror = function() {
                xhrSingleEcardViewer(xhr, 'Whoops! something went wrong, please try again.');
            }

            // SUCCESS
            xhr.onload = function() {
                xhrSingleEcardViewer(xhr, 'Email sent successfully.');
            }

            // xhr header(s)
            if(window.ie8 == false){ xhr.setRequestHeader('Content-Type', 'application/json'); }

            xhr.send(data);
        },

        replyToSender: function(){
            var toQueue = [];
            $('ul.recips > li').each(function(){
                temp = $(this);
                toQueue.push({
                    sender_name: temp.attr('data-sender_name'),
                    email: temp.text()
                })
            })
            var data = JSON.stringify({
                from: {
                    eligibilityID: $('#eligibility_id').val(),
                    name: $('input[name="recip-name"]').val(),
                    message: htmlEscape( $('textarea.multi-reply-message-body').val() )
                },
                to: toQueue
            });
            var url  = CONFIG.rw_domain + '/app/core/proc/Dashboard/Widget/EcardSend/ReplyToSenders';

            var xhr = createCORSRequest('POST', url);
            if (!xhr){
                $('#send-message').prop('disabled', false).removeClass('disabled');
//                alert('Sorry, your Web Browser does not support this functionality.');
                return;
            }

            // FAILURE
            xhr.onerror = function() {
                xhrEcardViewer(xhr, 'Whoops! something went wrong, please try again.');
            }

            // SUCCESS
            xhr.onload = function() {
                xhrEcardViewer(xhr, 'All emails sent successfully.');
            }

            // xhr header(s)
            if(window.ie8 == false){ xhr.setRequestHeader('Content-Type', 'application/json'); }

            xhr.send(data);
        },

        pdf: function(href){
            let pdfQueue = [];
            $("[id^=printCard]").each(function() { pdfQueue.push($(this).attr('id').replace(/\D/g, '')); });
            window.open(href);
        },

        printEcards: function(){
            myPrintQueue = [];
            $("[id^=printCard]").each(function() { myPrintQueue.push($(this).attr('id').replace(/\D/g, '')); });
            Renderer.buildPrintView(myPrintQueue);
            window.print();
        },

        printSingleCard: function(idString){
            var id = idString.replace(/\D/g,'');
            Renderer.buildSinglePrintView(id);
            window.print();
        },

        printLetter: function(){
            $('#viewer-print').empty().hide()
            var letterContents = '<div class=\"row-fluid\"><div class=\"col-offset-print-1 col-print-10\" id=\"printLogo\">' + $('#logo_container').html() + '</div><div class=\"col-offset-print-1 col-print-11\" id=\"printCeoImage\">' + $('#mobile_ceo_image').html() + '</div><div class=\"col-offset-print-1 col-print-10\" id=\"printLetterText\">' + $('#letter').html() + '</div></div>';
            $('#letter-print').empty()
                              .append(letterContents)
                              .find('div#mobile_ceo_image')
                              .removeClass('hidden-desktop');
            window.print();
        }
    }
}();

Renderer = function() {
    var privateEcards = [];
    var privateCategories = [];
    var privateCurrentView = [];

    return {
        getEcards: function(){
            var myEcards = [];
            for(var i=0; i<privateEcards.length; i++)
                myEcards.push(privateEcards[i])
            return myEcards;
        },

        setCurrentView: function(ecards){
            privateCurrentView = [];
            for(var i=0; i<ecards.length; i++)
                privateCurrentView.push(ecards[i])
            return;
        },

        getCurrentView: function(){
            var myprivateCurrentView = [];
            for(var i=0; i<privateCurrentView.length; i++)
                myprivateCurrentView.push(privateCurrentView[i])
            return myprivateCurrentView;
        },

        getCats: function(){
            var myCats = [];
            for(var i=0; i<privateCategories.length; i++)
                myCats.push(privateCategories[i])
            return myCats;
        },

        init: function(ecards){
            // Todo, add loading spinner and not reveal carousel until all images from MCFapps have been loaded.
            // Todo, error checking,
            // todo, error checking to see if array is empty.
            // Todo, xss escape input coming from backend

            /*
            * This is a little bit backwards since I build the views before setting the privateEcards variable.
            * I did this because once the views are built I can find the raw ecard message text, from the $('#content')
            * Basically the order 1. initialize Categories, 2. build views, 3. initialize privateEcards[] with rawMessageText
            */

            if(ecards.length < 1){ genericError() }

            for(var i=0; i<ecards.length; i++){
                // Ecard JSON error checking and escaping and serializing
                validate_object(ecards);

                ecards[i].id = ecards[i].meta.client_company_employee_eligibility_ecard_id;

                // Categories if privateCategories[] does not already contain ecards[i].meta.category add it.
                if(privateCategories.indexOf(ecards[i].meta.category_name) == -1){
                    privateCategories.push(ecards[i].meta.category_name);
                    var option = '<option data-display="'+ ecards[i].meta.category_display_value +
                        '" value='+ ecards[i].meta.category_name +'>'+
                        ecards[i].meta.category_display_value +'</option>';
                    $('select.categories').append(option);
                }
            }

            sortEcards(ecards, 'asc');
            Renderer.buildViews(ecards);
            Renderer.setCurrentView(ecards);

            for(var i=0; i<ecards.length; i++){
                // set raw message text w/o html markup
                ecards[i].meta.rawMessageText = $('#slide-show-carousel [data-ecard="'+ ecards[i].id +'"] #content').text();
                privateEcards.push(ecards[i]);

                // set eligibility id for reply to sender.  The meta.eligibility_id is the same for every ecard.
                if(ecards[i].meta.eligibility_id != '') { $('#eligibility_id').val(ecards[i].meta.eligibility_id); }
            }

            $('input[name="recip-name"]').val(ecards[0].receiver.recip_name);
            $('#recip-email').val(ecards[0].receiver.recip_email);
        },

        buildViews: function(ecards){
            $('#ecard-grid-carousel, #slide-show-carousel, #ecard-mobile div').remove();
            Sender.resetQueue();
            Sender.setCurrentView(ecards);

            Renderer.buildGridView(ecards);
            Renderer.buildSlideShowView(ecards);
            Renderer.buildMobileView(ecards);
            $('.carousel').carousel({ interval: 6000 });

            // Event Listener - Checkboxes for Grid and Slideshow Views
            $('input[type="checkbox"]').on('click', function(){
                $(this).is(':checked') ? Sender.addToQueue($(this).val()) : Sender.removeFromQueue($(this).val());
            });
        },

        buildGridView: function(ecards){
            var carousel = '<div id="ecard-grid-carousel" class="carousel slide"><div class="carousel-inner"></div></div>';
            $('.grid-container').append(carousel);

            if( ecards.length > 10 ) {
                var pointers = '<a class="carousel-control left" href="#ecard-grid-carousel" data-slide="prev">&lsaquo;</a><a class="carousel-control right" href="#ecard-grid-carousel" data-slide="next">&rsaquo;</a>';
                $('#ecard-grid-carousel .carousel-inner').after(pointers);
                $('#ecard-grid-carousel').append('<ol class="carousel-indicators"></ol>');
            }

            for(var i=0; i<ecards.length; i++){
                if(i % 10 == 0){
                    $('.carousel-indicators').append('<li data-target="#ecard-grid-carousel" data-slide-to="'+i/10+'"></li>');
                    $('#ecard-grid-carousel .carousel-inner').append('<div class="item slide-'+ i/10 +'"></div>');
                }

                var ecard = '<div data-ecard="'+ ecards[i].id +'" data-carousel-index="'+i+'" class="ecard-thumbnail-container">'+ ecards[i].meta.message +'<div class="check-box-container"><div class="name-contain"><p>'+ ecards[i].sender.sender_name +'</p></div><input type="checkbox" value="'+ ecards[i].id +'"/></div></div>';

                $('#ecard-grid-carousel .carousel-inner .slide-'+ Math.floor(i/10)).append(ecard);
                $('#ecard-grid-carousel .slide-0, li[data-slide-to="0"]').addClass('active');
            }

            // Event-listener - Click Grid view ecard
            $('#ecard-grid-carousel #ecard_card_01').on('click', function(){
                var carouselIndex = $(this).parent().attr('data-carousel-index');
                Renderer.switchView(false, carouselIndex);
            });

            // Strip Grid view elements of everything except thumbnail
            $('#ecard-grid-carousel #content').parent().remove();
            $('#ecard-grid-carousel #company_logo').remove();
        },

        buildSlideShowView: function(ecards){
            var carousel = '<div id="slide-show-carousel" class="carousel slide"><div class="carousel-inner"></div><a class="carousel-control left" href="#slide-show-carousel" data-slide="prev">&lsaquo;</a><a class="carousel-control right" href="#slide-show-carousel" data-slide="next">&rsaquo;</a></div>';
            $('.slide-show-container').append(carousel);

            for(var i=0; i<ecards.length; i++){
                var ecard = '<div data-ecard="'+ ecards[i].id +'" class="item ss-ecard">'+ ecards[i].meta.message +
                    '<div class="check-box-container row-fluid"><p>'+ ecards[i].sender.sender_name +
                    '<input type="checkbox" value="'+ ecards[i].id +'"></p></div></div>';
                $('#slide-show-carousel .carousel-inner').append(ecard);
            }
            $('#slide-show-carousel .item').first().addClass('active');

            if(ecards.length == 1)
                $('#slide-show-carousel .carousel-control').remove();
        },

        buildMobileView: function(ecards){
            for (var i=0; i<ecards.length; i++) {
                // fetch Ecard message body from slideshow View
                var ecardMessage = $('#slide-show-carousel [data-ecard="' + ecards[i].id + '"] #content').html();
                var ecard = '<div data-ecard="'+ ecards[i].id +'" class="mobile-ecard">'+ ecards[i].meta.message +
                    '<div class="reveal-btn-container"><p class="bold from-name">'+ ecards[i].sender.sender_name +'</p><button class="reveal-btn" data-ecard-btn="' + ecards[i].id +'">Show Message</button>'+
                    '<div class="mobile-message-body" style="display:none">'+ ecardMessage +
                    '<button href="#mobile-reply-modal" data-toggle="modal" class="mobile-reply-btn">Reply to '+ ecards[i].sender.sender_name +'</button></div></div></div>';

                $('#ecard-mobile').append(ecard);
            }
            $('#ecard-mobile #content').parent().remove();

            // Event-listener - Reveal Mobile ecard message
            $('.reveal-btn-container button.reveal-btn').on('click', function(){
                var ecardIndex = $(this).attr('data-ecard-btn');
                if( $(this).hasClass('open') ) {
                    $(this).removeClass('open').text('Show Message');
                    $('#ecard-mobile [data-ecard="'+ecardIndex+'"] .mobile-message-body').fadeOut();
                } else {
                    $(this).addClass('open').text('Hide Message');
                    $('#ecard-mobile [data-ecard="'+ecardIndex+'"] .mobile-message-body').slideDown();
                }
            });
        },

        buildPrintView: function(ecards){
            $('#letter-print').empty().hide();
            var temp = $('#viewer-print');
            temp.empty();
            for(var i=0; i<ecards.length; i++){
                var element = $('[data-card="' + ecards[i] + '"]');
                var ecard_image = element.find('img')
                                         .attr('src');
                var ecard_message = element.find('div.colleague_message, div#manager_message')
                                           .clone()
                                           .remove()
                                           .end()
                                           .html();
                var ecard_sender_name = element.find('div.senderName')
                                               .text();
                var myEcard = '<div class=\"printedCards row\" style=\"\" data-ecard=\"' + ecards[i] + '\"><div class=\"colleague_container col-print-6 col-offset-print-3\"><div class=\"col-print-10 col-offset-print-1\" style=\"\"><img class=\"printImage\" style=\"\" src=\"' + ecard_image + '\"></div><div class=\"col-print-10 col-offset-print-1 colleague_message\" style=\"\"><div class=\"col-print-12 px-3\">'+ ecard_message + '<div class=\"col-print-12 px-3 senderName\">'+ ecard_sender_name + '</div></div></div></div></div>';
                temp.append(myEcard);
            }
        },

        buildSinglePrintView: function(ecard){
            $('#letter-print').empty().hide();
            var element = $('[data-card="' + ecard + '"]');
            var ecard_image = element.find('img')
                                     .attr('src');
            var ecard_message = element.find('div.colleague_message, div#manager_message')
                                       .clone()
                                       .remove()
                                       .end()
                                       .html();
            var ecard_sender_name = element.find('div.senderName')
                                           .text();
            var myEcard = '<div class=\"printedCards row\" style=\"\" data-ecard=\"' + ecard + '\"><div class=\"colleague_container col-print-6 col-offset-print-3\"><div class=\"col-print-10 col-offset-print-1\" style=\"\"><img class=\"printImage\" style=\"\" src=\"' + ecard_image + '\"></div><div class=\"col-print-10 col-offset-print-1 colleague_message\" style=\"\"><div class=\"col-print-12 px-3\">'+ ecard_message + '<div class=\"col-print-12 px-3 senderName\">'+ ecard_sender_name + '</div></div></div></div></div>';
            $('#viewer-print').empty().append(myEcard);
        },

        switchView: function(gotoGrid, index){
            if(index) // Switch to specific slide
                $('#slide-show-carousel').carousel(parseInt(index));

            // Toggle button
            var slide;
            if(gotoGrid) { // switch to Grid
                $('.slide-show-container').addClass('hidden');
                $('.grid-container').removeClass('hidden');
                $('a.switch-to-ss').removeClass('bold');
                $('a.switch-to-grid').addClass('bold');
                slide = -4;
            } else { // Switch to Slideshow
                $('.grid-container').addClass('hidden');
                $('.slide-show-container').removeClass('hidden');
                $('a.switch-to-grid').removeClass('bold');
                $('a.switch-to-ss').addClass('bold');
                slide = -33;
            }
            // toggle slider
            $('i.view-button').animate({
                right: slide
            });
        },

        filter: function(){
            $('ul.error-list').remove();
            $('.cat-selected').removeClass('cat-selected');
            var order = $('select.sort').val();
            var input = htmlEscape($('input.search-input').val().toLowerCase());
            var cat   = $('select.categories').val();
            $('select.categories option[value="'+ cat +'"]').addClass('cat-selected');
            var cat_name 	  = $('.cat-selected').attr('data-display') || 'All Categories';
            var myEcards      = sortEcards(Renderer.getCurrentView(), order);
            var myCats		  = Renderer.getCats();
            var searchResults = [];
            var catResults    = [];
            var r             = [];

            // Todo: fix all this shit.
            // Search
            if(input != ''){
                for(var i=0; i<myEcards.length; i++){
                    var message = myEcards[i].meta.message.toLowerCase();
                    var name 		= myEcards[i].sender.sender_name.toLowerCase();
                    if(message.indexOf(input) > -1 || name.indexOf(input) > -1)
                        searchResults.push(myEcards[i]);
                }
            } else {
                for(var i=0; i<myEcards.length; i++){
                    searchResults.push(myEcards[i]);
                }
            }

            // Categories
            if(cat != 'none')
                for(var i=0; i<myEcards.length; i++)
                    if(cat == myEcards[i].meta.category_name)
                        catResults.push(myEcards[i])

            // Results - Search
            for(var i = 0; i < searchResults.length; i++){
                if(cat != 'none'){
                    if(catResults.indexOf(searchResults[i]) > -1 && r.indexOf(searchResults[i]) == -1){
                        r.push(searchResults[i]);
                    }
                } else if(r.indexOf(searchResults[i]) == -1){
                    r.push(searchResults[i]);
                }
            }

            // Results - Cat
            for(var i=0; i<catResults.length; i++){
                if(input != ''){
                    if(searchResults.indexOf(catResults[i]) > -1 && r.indexOf(catResults[i]) == -1){
                        r.push(catResults[i]);
                    }
                } else if(r.indexOf(catResults[i]) == -1){
                    r.push(catResults[i]);
                }
            }

            // Error: query returned no results.
            if(r.length == 0){
                var error_msg = '<div class="item slide-0 active">'+
                    '<p class="error-msg">Search returned no Ecards, please refine your search and try again.</p>'+
                    '<ul class="error-list">'+
                    '<li class="error-input"><p class="error">Search Criteria: '+ input +'</p></li>' +
                    '<li class="error-category"><p class="error">Category: '+ cat_name +'</p></li>' +
                    '</ul>'+
                    '</div>';

                Renderer.buildViews([]);
                $('.carousel-inner').append(error_msg);
                $('.footer-buttons').addClass('hidden');
            } else {
                $('.footer-buttons').removeClass('hidden');
                Renderer.buildViews(r)
            }
            return;
        }
    }
}();

function htmlEscape(str){return String(str).replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace('/', '&#x2F');}

function genericError() {
    $(document).ready(function(){
        $('#error-modal').modal('toggle');
    });
}

function xhrSingleEcardViewer(xhr, status){
    $('#single-reply-modal #singleSentStatus').text(status);
    $('.send-message-single .fa-spinner, label#single-reply-textarea').addClass('d-none');
    $('textarea.single-reply-message-body').val('');
    $('.send-message-single').prop('disabled', false).removeClass('disabled');
}

function xhrEcardViewer(xhr, status){
    $('#multi-reply-modal #multiSentStatus').text(status);
    $('#send-message .fa-spinner').addClass('d-none');
    $('textarea.multi-reply-message-body').val('');
    $('#send-message').prop('disabled', false).removeClass('disabled');
}

// http://www.html5rocks.com/en/tutorials/cors/
function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {
        xhr.open(method, url, true);
    } else if (typeof XDomainRequest != "undefined") {
        xhr = new XDomainRequest();
        xhr.open(method, url);
    } else {
        xhr = null;
    }
    return xhr;
}

function validate_object(object) {
    for (var i in object) {
        if (object[i] === null || object[i] === undefined) {
            object[i] = '';
        } else if (typeof object[i] === 'object') {
            validate_object(object[i]);
        }
    }
}

function sortEcards(ecards, order){
    for (var i=0; i<ecards.length; i++)
        if(ecards[i].sender.sender_name == undefined || ecards[i].sender.sender_name == null)
            ecards[i].sender.sender_name = '';

    ecards.sort(function(a, b){
        if(a.sender.sender_name.toLowerCase() < b.sender.sender_name.toLowerCase())
            return -1;
        else if(a.sender.sender_name.toLowerCase() > b.sender.sender_name.toLowerCase())
            return 1;
        else
            return 1;
    });

    if(order == 'des') { ecards.reverse() }
    return ecards;
}
