// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('@fortawesome/fontawesome-free')
require('channels')
require('bootstrap')
require('../src/ecardViewer')
require('../src/home')
require('../src/analytics')

const images = require.context('../images', true)

import 'stylesheets/application'
import '@fortawesome/fontawesome-free/scss/fontawesome';
import '@fortawesome/fontawesome-free/scss/regular';
import '@fortawesome/fontawesome-free/scss/solid';
import 'stylesheets/default'
import 'stylesheets/overrides'
import 'stylesheets/marketing_page'
import 'stylesheets/error'
import $ from 'jquery'
import gtag from 'src/analytics'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

document.addEventListener('turbolinks:load', function() {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
})
